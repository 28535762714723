/* .active {
  background-color: #1d4ed8;
} */
:root {
  --color-131921: #131921;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: white;
  position: -webkit-sticky;
  position: sticky;
  top: -5px;
  z-index: 2;
}

nav .title {
  font-size: 1.5rem;
  margin: 1rem;
  font-weight: bold;
  text-decoration: none;
  color: white;
}
.menu-cover {
  background-color: white;
  border: 1px solid darkslategrey;
  padding: 0.5rem;
  margin: 0 0.5rem 0 1.5rem;
}

nav ul {
  display: flex;
}

nav ul li {
  display: flex;
  /* align-items: center; */
  /* margin-right: 12rem; */
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: #131921;
  /* border: 1px solid #bf4800; */
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: 1.4rem;
}

.menu-cover:hover {
  background-color: #bf4800;
  color: white;
}

nav .menu {
  display: none;
  position: absolute;
  top: 0.9rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.25rem;
  height: 2rem;
}

nav .menu span {
  height: 0.4rem;
  width: 100%;
  background-color: brown;
  border-radius: 0.2rem;
}

.close-btn {
  background: transparent;
  border: none;
  outline: none;
  color: 'black';
  display: inline-block;
  font-size: 1.5rem;
  visibility: hidden;
  opacity: 0;
  display: none;
}

@media (max-width: 1050px) {
  nav .menu {
    display: flex;
  }

  nav {
    flex-direction: column;
    align-items: flex-start;
  }

  nav ul {
    display: none;
  }

  nav ul.open {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 3rem;
    margin-bottom: 0.25rem;
  }

  nav ul li {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    height: 100%;
    text-align: center;
    margin-bottom: 3rem;
    align-items: center;
  }

  nav ul li a {
    background-color: #131921;
    align-items: center;
    width: 20rem !important;
    margin: 0.2rem 0.2rem;
    padding: 0.1rem 0.5rem 0.1rem 0.5rem;
    margin-bottom: 0.1rem !important;
    color: white !important;
  }

  .linky {
    display: none;
    visibility: hidden;
    opacity: 0;
  }

  .menu-cover {
    background-color: #131921;
    margin: 0.2rem 0.2rem;
    /* margin-right: 1.5rem; */
    margin-bottom: 0.1rem !important;
    color: white !important;
  }
}
@media only screen and (max-width: 380px) {
  nav ul li a {
    width: 20rem !important;
  }
}

@media only screen and (min-width: 390px) and (max-width: 560px) {
  nav ul li a {
    width: 23rem !important;
  }
}
@media only screen and (min-width: 562px) and (max-width: 760px) {
  nav ul li a {
    width: 33rem !important;
  }
}
@media only screen and (min-width: 762px) and (max-width: 1050px) {
  nav ul li a {
    width: 60rem !important;
  }
}

@media (min-width: 1050px) {
  nav ul li:nth-child(2) {
    display: none;
  }
  nav ul li:nth-child(3) {
    display: none;
  }
  nav ul li:nth-child(4) {
    display: none;
  }
  .dontshow {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}
