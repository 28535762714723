@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&family=Roboto:wght@700;900&family=Rubik:wght@400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-family: 'Roboto', sans-serif;
  font-family: 'Rubik', sans-serif;
}
:root {
  --color-131921: #131921;
  --color-3b4149: #3b4149;
  --color-febd69: #febd69;
  --color-232f3e: #232f3e;
  --color-bf4800: #bf4800;
  --color-f5f5f7: #f5f5f7;
  --color-ededed: #ededed;
  --color-eaeaea: #eaeaea;
  --color-777777: #777777;
  --color-1c1c1b: #1c1c1b;
  --color-f2f2f2: #f2f2f2;
}

.gap-2 {
  gap: 2px;
}
.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}
.gap-15 {
  gap: 15px;
}
.gap-30 {
  gap: 30px;
}
.gap-50 {
  gap: 50px;
}
.gap-70 {
  gap: 70px;
}
.gap-100 {
  gap: 100px;
}
.gap-200 {
  gap: 200px;
}

.error {
  color: red;
  font-size: small;
}

.buttonext {
  background-color: var(--color-131921);
  color: white;
  font-size: 0.8rem;
  padding: 0.5rem 1rem;
  border-radius: 0.6rem;
  border: 1px solid white;
}

.cont-wrapper {
  min-height: 90vh;
  background-color: var(--color-f5f5f7);
}

/* Spinner */
.spinner-wrapper {
  background-color: #272727;
  opacity: 0.3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
}

.spinner-border {
  height: 10rem;
  width: 10rem;
}

@media only screen and (max-width: 576px) {
  .spinner-border {
    height: 3rem !important;
    width: 3rem !important;
  }
}
@media only screen and (max-width: 300px) {
  .spinner-border {
    height: 2rem !important;
    width: 2rem !important;
  }
}

/* Nav Bar */
.form-control {
  width: 0.5rem;
}

.form-controll {
  color: #131921;
  box-shadow: none;
  width: 20rem;
  height: 4rem;
  border-radius: 0.5rem;
  padding: 1rem 0.3rem 1rem 0.3rem;
}

.select-cat {
  color: 'black';
  box-shadow: none;
  width: 20.5rem;
  height: 3.5rem;
  border-radius: 0.5rem;
  border: 1px solid black;

  /* padding: 1rem 0.3rem 1rem 0.3rem; */
}

.select-cat ::placeholder {
  color: black;
  opacity: 1; /* Firefox */
}

.form-floating > input::placeholder {
  color: black;
  opacity: 1; /* Firefox */
}

@media only screen and (max-width: 576px) {
  .form-controll {
    height: 1.5rem;
    width: 13rem;
  }
  .select-cat {
    height: 3rem;
    width: 13rem;
    margin-top: 0.5rem;
  }
}
@media only screen and (max-width: 300px) {
  .form-controll {
    width: 10rem;
  }
}

#navbar {
  position: -webkit-sticky;
  position: sticky;
  top: -5px;
  z-index: 2;
  /* position: sticky;
  top: 0;
  left: 0;
  z-index: 100; */
  padding: 0.5rem 0.5rem;
}
/* end of Nav Bar */

#signoneform {
  padding: 5rem 2rem 0 2rem;
}
#signtwoform {
  padding: 2rem 2rem 0 2rem;
}

.signupbtn {
  background-color: var(--color-131921);
  border-radius: 0.5rem;
}
.forgot-password-input {
  background: transparent;
  color: white !important;
  border-radius: 0.2rem;
  width: 23rem;
  padding-left: 0.5rem;
  height: 2.5rem;
  border: 2px solid var(--color-777777);
}

/* Image by <a href="https://www.freepik.com/free-psd/international-day-happiness-banner_12984259.htm#query=cash%20template&position=26&from_view=search&track=ais&uuid=f3608aa3-52dd-4e26-af2f-33320ff58892">Freepik</a> */

/* Home*/
.home-wrapper {
  background-size: cover;
  /* background-position: center; */
  background-color: var(--color-f5f5f7);
  padding: 0.1rem 1rem 0rem 1rem;
  min-height: 60vh;
}

.home-wrapper-divv {
  background-color: white;
}

.carousel {
  height: 19rem;
  margin-top: 1rem;
  color: white;
  background-color: var(--color-bf4800);
}

.carousel-caption {
  color: white;
}

.carousel-inner {
  color: white;
}

.image-cara {
  width: 100px;
  height: 19rem;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .image-cara {
    height: 200px;
  }
  .carousel-inner {
    height: 200px;
  }
  .carousel-slide {
    height: 200px;
  }
  .carousel {
    height: 200px;
  }
  .home-wrapper h6 {
    font-size: 1rem;
  }
}
.section-header {
  background-color: var(--color-bf4800);
  height: 2.5rem;
}
/* end of Home */

/* Product card */
.blog-card {
  border-radius: 10px;
  width: 10rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 950px) {
  /* For mobile phones: */
  .blog-card {
    height: 10rem;
    width: 10rem !important;
  }
}
@media only screen and (max-width: 576px) {
  .order-card {
    height: 18rem !important;
    width: 15rem !important;
  }
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .col-3 {
    width: 50%;
  }
  .blog-img {
    height: 5rem;
    width: 5rem;
  }
  .blog-card {
    height: 5rem;
    width: 5rem !important;
    margin-bottom: 3rem;
  }
}

.blog-img {
  max-height: 10rem;
  /* width: 10rem; */
}

.blog-card img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.order-card p.date {
  font-size: 0.8rem;
  line-height: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0;
  font-weight: 400;
  padding: 0;
  color: var(--color-777777);
}

.order-card h5 {
  font-size: 1rem;
  color: var(--color-131921);
}

.order-card p.desc {
  font-size: 0.9rem;
  line-height: 0.8rem;

  letter-spacing: 0.1rem;
  color: var(--color-777777);
}

@media only screen and (max-width: 950px) {
  .other-product-images > div {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
}

/* end of Product card */

/* button */
.button {
  background-color: var(--color-232f3e);
  color: white;
  font-size: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

.button:hover {
  background-color: var(--color-febd69);
  color: var(--color-131921);
}
.orderbutton:hover {
  background-color: var(--color-febd69);
  color: var(--color-131921);
}
.orderbuttonT:hover {
  background-color: var(--color-febd69);
  color: var(--color-131921);
}

/* end of button */

/* Footer */
footer {
  background-color: var(--color-232f3e);
}
footer:not(:first-child) {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
footer .input-group-text {
  background-color: var(--color-232f3e);
  padding: 8px 17px;
  color: white;
}
/* End of Footer */

.popup-main {
  background-color: #272727;
  opacity: 0.9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
}
.popup {
  height: 400px;
  width: 50%;
  background-color: white;
  position: absolute;
  top: 25%;
  right: 25%;
}

@media only screen and (max-width: 950px) {
  /* For mobile phones: */
  .popup {
    width: 80%;
    right: 10%;
    top: 10%;
    padding: 0.5rem;
  }
  .popup-header h1 {
    font-size: 1.2rem;
  }
}

.popup-header {
  color: orangered;
  display: flex;
  justify-content: space-between;
  padding: 0 30px 0 15px;
  border-bottom: 2px solid black;
}

.order-warning {
  color: red;
  font-size: 10px;
  margin-top: 5px;
}

.useracc1 {
  display: flex;
  align-items: center;
  background-color: var(--color-eaeaea);
  border-radius: 2px;
}
.useracc2 {
  display: flex;
  align-items: center;
  border-radius: 2px;
}

.useracc2:hover {
  background-color: var(--color-eaeaea);
}

.storecrdt {
  font-size: 200px;
}

/* Main Product page */

.description-wrapper h4,
.reviews-wrapper h3 {
  font-size: 26px;
  color: var(--color-1c1c1b);
  margin-bottom: 10px;
}

.description-wrapper p {
  font-size: 14px;
  color: var(--color-777777);
}

.review-inner-wrapper {
  background-color: white;
  padding: 30px;
}
.review-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.review-head h4 {
  font-size: 18px;
  color: var(--color-1c1c1b);
}
.review-head p,
.review-head a {
  font-size: 14px;
  color: var(--color-777777);
}

.review-form h4 {
  font-size: 16px;
  color: var(--color-777777);
}

.review-form {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.review:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.review p {
  font-size: 14px;
  color: var(--color-777777);
}

.main-product-image {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}
.main-product-image > div {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.big-image {
  width: 500px;
  height: 500px;
  object-fit: cover;
}

.other-product-images {
  background-color: white;
  padding: 20px;
}

.other-product-images > div {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 48%;
}

@media only screen and (max-width: 950px) {
  .other-product-images > div {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
}

.main-product-image .js-image-zoom__zoomed-image {
  left: 100px !important;
  z-index: 1000;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.title {
  font-size: 0.9rem;
  font-weight: 100;
  height: 1.8em;
  width: 8.5em;
}
.date {
  font-weight: bolder;
}

.main-product-details {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}

.main-product-details h3.title {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 10px;
}

.main-product-details p.price {
  font-size: 15px;
  line-height: 20px;
  font-weight: 600;

  margin-bottom: 10px;
}

.main-product-details a.review-btn,
.t-review {
  font-size: 14px;
  color: var(--color-777777);
}

.product-heading {
  font-size: 14px;
  color: var(--color-1c1c1b);
  margin-bottom: 0px;
}

.product-data {
  font-size: 14px;
  color: var(--color-777777);

  margin-bottom: 0px;
}

.main-product-details a {
  font-size: 14px;
  color: var(--color-777777);
}

.order-img-one {
  height: 5rem;
  width: 5rem;
}

.main-product-wrapper {
  background-color: var(--color-eaeaea);
}

.checkout-wrapper {
  background-color: var(--color-eaeaea);
}

@media only screen and (max-width: 950px) {
  .col-5 {
    width: 100%;
  }
  .col-6 {
    width: 100%;
  }
  .col-7 {
    width: 100%;
  }
  .small-image {
    display: none;
  }
}

/* end of Main Product page */

/* account details */
.acc-det1 {
  width: 20rem;
  height: 10rem;
  border-radius: 1rem;
  border: 2px solid var(--color-febd69);
  padding: 1rem 1rem 1rem 1rem;
}

@media only screen and (max-width: 768px) {
  .acc-det1 {
    width: 17rem;
    height: 10rem;
  }
}
/* end of account details */

.oderit {
  background-color: var(--color-bf4800);
}

.oderlist1 {
  margin-right: 20rem;
}
.oderlist2 {
  margin-right: 20rem;
}
.oderlist3 {
  margin-right: 20rem;
}
.vendr-prdt-item {
  margin: 0 5rem 0 10rem;
}
.vendr-prdt-item1 {
  margin: 0 5rem 0 5rem;
}

@media only screen and (max-width: 398px) {
  #vendr-prdt-item3 {
    display: none;
  }
  .vendr-prdt-item {
    margin: 0 0.5rem 0 1rem;
  }
  .vendr-prdt-item1 {
    margin: 0 0.5rem 0 1rem;
  }

  .title {
    font-size: 0.6rem;
    font-weight: 100;
    height: 1.2em;
    width: 6.5em;
  }
}
@media only screen and (min-width: 400px) and (max-width: 798px) {
  #vendr-prdt-item3 {
    display: none;
  }

  .vendr-prdt-item {
    margin: 0 1rem 0 8rem;
  }
  .vendr-prdt-item1 {
    margin: 0 1rem 0 8rem;
  }

  .title {
    font-size: 0.6rem;
    font-weight: 100;
    height: 1.5em;
    width: 7.5em;
  }
}

@media only screen and (max-width: 499px) {
  #vendr-prdt-item4 {
    display: none;
  }
}

/* pagination */
.pagination {
  list-style: none;
  height: 31.5px;
  width: 31.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  cursor: pointer;
}

.activ {
  background-color: #1e50ff;
  border-radius: 50%;
}

.page-item {
  list-style: none;
  padding: 2px 12px;
  height: 31.5px;
  width: 31.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}

/* end of pagination */

/* order details */

.orderbutton {
  background-color: var(--color-232f3e);
  color: white;
  font-size: 10px;
  padding: 6px 15px;
  border-radius: 25px;
}
.orderbuttonT {
  background-color: var(--color-232f3e);
  width: 100%;
  color: white;
  font-size: 20px;
  padding: 9px 20px;
  border-radius: 7px;
  border: none;
}

.tot1 {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* justify-content: space-between; */
}
.tot2 {
  display: flex;
  /* justify-content: center; */

  /* justify-content: space-between; */
}

.total-price {
  margin-left: 0.5rem;
  font-weight: bold;
}

@media only screen and (max-width: 950px) {
  .order-flex {
    display: block;
  }

  .oda-det1 h4 {
    font-size: 1rem;
  }
  .product-title {
    font-size: 0.8rem;
  }
  .product-desc {
    font-size: 0.5rem;
  }
  .total {
    font-size: 0.8rem;
  }
  .total-price {
    font-size: 0.8rem;
  }

  .tot2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .orderbuttonT {
    width: 50%;
    color: white;
    font-size: 0.9rem;
    padding: 4px 10px;
    border-radius: 7px;
  }
  .price-pln {
    font-size: 0.7rem !important;
  }
  .price-label {
    font-size: 0.9rem !important;
  }
}

.price-label {
  font-size: 1rem;
  font-weight: bold;
}

.price-pln {
  font-size: 1rem;
}

.odadet {
  font-size: 1.5rem;
}
.odadet span {
  font-size: 2rem;
}
@media only screen and (max-width: 768px) {
  .odadet {
    font-size: 1.2rem;
  }
  .odadet span {
    font-size: 1.5rem;
  }
}
/* end of order details */
